import { isJSON } from '@/components/shared/Hooks';

const storeAuth = {
  token: '',
  tokkoBrokerHost: '',
};

async function updateToken() {
  storeAuth.tokkoBrokerHost = localStorage.getItem('tokkoBrokerHost');
  storeAuth.token = localStorage.getItem('jwt');
}

class ApiService {
  static async getRequestOptions(method, requestData = null) {
    if (storeAuth.token === '' && process.env.NODE_ENV !== 'test') {
      await updateToken();
    }
    const requestOptions = {
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: storeAuth.token,
      },
    };

    if (requestData) {
      requestOptions.body = JSON.stringify(requestData);
    }

    return requestOptions;
  }

  static async performRequest(url, requestOptions, baseUrl) {
    const composedURL = `${baseUrl || storeAuth.tokkoBrokerHost}${url}`;
    const response = await fetch(composedURL, requestOptions);
    let data;
    if (process.env.NODE_ENV !== 'test') {
      if (response.headers.get('Content-Type') === 'application/vnd.ms-excel') {
        data = await response.blob();
      } else if (response.headers.get('Content-Type') === 'application/json') {
        data = await response.json();
      } else {
        const text = await response.text();
        data = isJSON(text) ? JSON.parse(text) : {};
      }
    } else {
      data = await response.json();
    }

    if (!response.ok) {
      const error = new Error(data.message || 'Request failed');
      error.response = data;

      if (response.status === 401 && process.env.NODE_ENV) {
        if (window.location.href.includes('outsidelayout')) {
          window.parent.postMessage(
            'outsidelayoutUnauthorized',
            storeAuth.tokkoBrokerHost
          );
        }
        localStorage.setItem('tokkoBrokerHost', null);
        localStorage.setItem('jwt', null);
        window.location.assign(`${storeAuth.tokkoBrokerHost}/home/`);
      }
      throw error;
    }

    return data;
  }

  static async parseJSON(response) {
    return response.text().then((text) => (text ? JSON.parse(text) : {}));
  }

  static async get(url, baseUrl = storeAuth.tokkoBrokerHost) {
    const requestOptions = await this.getRequestOptions('GET');
    return this.performRequest(url, requestOptions, baseUrl);
  }

  static async post(url, requestData, baseUrl = storeAuth.tokkoBrokerHost) {
    const requestOptions = await this.getRequestOptions('POST', requestData);
    return this.performRequest(url, requestOptions, baseUrl);
  }

  static async delete(url, requestData, baseUrl = storeAuth.tokkoBrokerHost) {
    const requestOptions = await this.getRequestOptions('DELETE', requestData);
    return this.performRequest(url, requestOptions, baseUrl);
  }

  static async put(url, requestData, baseUrl = storeAuth.tokkoBrokerHost) {
    const requestOptions = await this.getRequestOptions('PUT', requestData);
    return this.performRequest(url, requestOptions, baseUrl);
  }

  static async patch(url, requestData, baseUrl = storeAuth.tokkoBrokerHost) {
    const requestOptions = await this.getRequestOptions('PATCH', requestData);
    return this.performRequest(url, requestOptions, baseUrl);
  }
}

export default ApiService;
