/* eslint-disable import/no-named-as-default */
import { combineReducers } from '@reduxjs/toolkit';
import AuthSlice from './slices/AuthSlice';
import LayoutSlice from './slices/LayoutSlice';
import UserPreferencesSlice from './slices/UserPreferencesSlice';
import UserSlice from './slices/UserSlice';
import CalendarEvents from './slices/components/calendarEvents/CalendarEventsSlice';
import MessengerSlice from './slices/components/chat/MessengerSlice';
import ToastSlice from './slices/components/toast/ToastSlice';
import QuickDisplaySlice from './slices/components/quickDisplay/QuickDisplaySlice';
import OpenNaventSlice from './slices/components/openNavent/OpenNaventSlice';
import PropertyConfiguratorSlice from './slices/components/propertyConfigurator/PropertyConfiguratorSlice';
import SenderSlice from './slices/components/sender/SenderSlice';
import NotificationSlice from './slices/NotificationSlice';

const rootReducer = combineReducers({
  AuthSlice,
  LayoutSlice,
  UserPreferencesSlice,
  CalendarEvents,
  UserSlice,
  MessengerSlice,
  ToastSlice,
  QuickDisplaySlice,
  OpenNaventSlice,
  PropertyConfiguratorSlice,
  SenderSlice,
  NotificationSlice,
});

export default rootReducer;
