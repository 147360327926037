/* eslint-disable import/prefer-default-export */
export const getAssignedFilter = (MEMBER, trayID) => {
  const trayMap = {
    all: {
      $or: [
        {
          type: { $in: ['WhatsAppContact', 'Colleague'] },
          members: MEMBER,
        },
        {
          type: 'WhatsAppGuest',
          frozen: true,
          members: MEMBER,
        },
      ],
    },

    whatsapp: {
      $or: [
        {
          type: { $in: ['WhatsAppContact'] },
          members: MEMBER,
        },
        {
          type: 'WhatsAppGuest',
          frozen: true,
          members: MEMBER,
        },
      ],
    },

    colleague: {
      type: { $in: ['Colleague'] },
      members: MEMBER,
    },
  };

  return trayMap[trayID];
};

export const getUnnassignedFilter = (MEMBER, trayID) => {
  const trayMap = {
    all: {
      type: {
        $in: ['WhatsAppGuest'],
      },
      members: MEMBER,
      frozen: false,
    },

    whatsapp: {
      type: {
        $in: ['WhatsAppGuest'],
      },
      members: MEMBER,
      frozen: false,
    },

    colleague: {
      type: { $in: ['not-channel'] },
      members: MEMBER,
    },
  };

  return trayMap[trayID];
};
